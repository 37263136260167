import get from 'lodash-es/get'
import { alerts } from '../../stores/index.js'

/**
 * Our error handler for Axios. If a config with errorHandle === false
 * is passed in an Axios call, the default global handling is ignored.
 *
 * @param error
 * @returns A new rejected Promise.
 */
export default error => {
    if (get(error.config, 'useGlobalErrorHandler', true) === false) {
        return Promise.reject(error)
    }

    if (error.response && error.response.status === 403) {
        alerts.add('danger', 'error.http_forbidden')
    } else if (error.response && error.response.status !== 400) {
        alerts.add('danger', 'error.unknown_error')
    }

    return Promise.reject(error)
}
